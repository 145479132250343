import React from 'react';
import styles from './references.module.scss';
import SectionTitle from '../../elements/SectionTitle/SectionTitle';
import API from '../../Services/API';
import { Avatar, CardHeader, Card, CardContent, Typography } from '@mui/material';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Markup } from 'interweave';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';



const References = ({assets}) => {
  const [list, setList] = React.useState([]);
  const [bgImg,setBgImg] = React.useState('')

  React.useEffect(() => {
    const api = new API('reference')

    api.render()
    .then(data => setList(data))
  },[])

  React.useEffect(() => {
    if(assets){
      setBgImg(assets.TestimonialsBackground)
    }
  },[assets])

 
  

  return <div className={styles.References}>
    <div 
       style={{
        backgroundImage: `url(${bgImg})`
      }}
      className={styles.Overlay}></div>
  <SectionTitle title="References" />
    <Carousel
      infiniteLoop
      showIndicators={false}
    >
    {list.map(item =><Card key={item.id}>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        <Markup content={documentToHtmlString(item.testimonial)} />
        </Typography>
      </CardContent>
      <CardHeader
        avatar={
          <Avatar alt={item.personName} src={item.image.fields.file.url} />
        }
        title={item.personName}
        subheader={item.title}
      />
    </Card>)}
    </Carousel>
</div>
};
export default References;
