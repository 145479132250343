import React, { useEffect, useState } from 'react';
import styles from './experience.module.scss';
import API from '../../Services/API'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SectionTitle from '../../elements/SectionTitle/SectionTitle';
import { Markup } from 'interweave';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import CustomDate from '../../elements/CustomDate/CustomDate';

const Experience = () => {
  const [experienceList, setExperienceList] = useState([]);


  useEffect(() => {
    const api = new API('experience', 'from', true)

    api.render()
    .then(data => setExperienceList(data))
  },[])

  return <section className={styles.Experience}>
  {experienceList 
  ? <>
    <SectionTitle title="Experience" />
    
    {
    experienceList.map(item => (
      <Accordion  key={item.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
           <Typography sx={{ width: '75%', flexShrink: 0 }}>
            {item.companyName} <span className={styles.Title}>{item.myTitle}</span>
          </Typography>
          <Typography sx={{ textAlign:"right", width:"25%", color: 'text.secondary' }}><CustomDate date={item.from} /> - {<CustomDate date={item.to} />}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          
        <Markup content={documentToHtmlString(item.experience)} />
        </AccordionDetails>
      </Accordion>
    ))
    }</>
  : <span className="loading"></span> }
</section>
};

export default Experience;
