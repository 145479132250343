import React from 'react';
import styles from './CustomDate.module.scss';

const CustomDate = ({date}) => {

  const convertDate = date => {
    if(!date) return 'NOW'
    
    const d = new Date(date)
    const months = ['January', 'February', 'March' , 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return months[d.getMonth() + 1] + ' ' + d.getFullYear()
  }

  return<span className={styles.CustomDate}>
    {convertDate(date)}
  </span>
};

export default CustomDate;
