import React from 'react';
import styles from './contact-me.module.scss';
import SectionTitle from '../../elements/SectionTitle/SectionTitle';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Button  from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Unstable_Grid2';
import { Alert, Snackbar } from '@mui/material';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object({
  name: yup.string().required('Name is required').min(3, 'Please insert valid name'),
  email: yup.string().required('Email is required').email('Please enter Valid Email'),
  phone: yup.string().required('Phone is required').matches(phoneRegExp, 'Phone number is not valid'),
  message: yup.string().required('Email is required')
  
}).required();

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactMe = ({ assets }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [bgImg,setBgImg] = React.useState('')

  const [severityLevel, setSeverityLevel] = React.useState('info')
  const [open, setOpen] = React.useState(false)
  const [responseMessage, setResponseMessage] = React.useState('')


  React.useEffect(() => {
    if(assets){
      setBgImg(assets.ContactMeBackground)
    }
  }, [assets])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSubmit = (values, actions) => {
    fetch("/", {                                 
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...values,
      }),
    })
      .then(() => {
        setOpen(true);
        setResponseMessage('Thank you for your submission, I will contact you back as soon as possible')
        setSeverityLevel('success')
      })
      .catch(error => {
        setOpen(true);
        setResponseMessage('There was an error while submitting the form, sorry for any inconvenience, please contact me directly on mariofayek87@gmail.com')
        setSeverityLevel('error')
      })
      .finally(() => actions.setSubmitting(false))

    
  }

  return (<div id="contactMe" className={styles.ContactMe}>
    <div 
       style={{
        backgroundImage: `url(${bgImg})`
      }}
      className={styles.Overlay}></div>
    <SectionTitle title="Contact me" />
    <form 
      onSubmit={handleSubmit(onSubmit)} 
      name="Contact"
      netlify 
      data-netlify-honeypot="bot-field"
      data-netlify="true">
      <input type="hidden" name="form-name" value="Contact" />
      <div hidden>
        <input name="bot-field" />
      </div>
      
      <Grid container spacing={2}>
        <Grid xs={6}>
          <TextField 
            {...register("name")} 
            error={errors.name}
            helperText={errors.name?.message}
            label="Name" 
            fullWidth
            sx={{ mb: 4 }}
            variant="outlined" />
        </Grid>
        <Grid xs={6}>
          <TextField 
            {...register("email")} 
            label="Email" 
            error={errors.email}
            helperText={errors.email?.message}
            fullWidth
            sx={{ mb: 4 }}
            variant="outlined" />

        </Grid>
        <Grid xs={6}>
          <TextField
            {...register("phone")} 
            label="Phone" 
            error={errors.phone}
            helperText={errors.phone?.message}
            fullWidth
            sx={{ mb: 4 }}
            variant="outlined" />
        </Grid>
        <Grid xs={6}>
          <TextField 
            {...register("website")} 
            label="Website" 
            error={errors.website}
            helperText={errors.website?.message}
            fullWidth
            sx={{ mb: 4 }}
            variant="outlined" />
        </Grid>
      </Grid>
      <TextField 
        {...register("message")} 
        label="Message" 
        error={errors.message}
        helperText={errors.message?.message}
        fullWidth
        sx={{ mb: 4 }}
        multiline
        rows={5}
        variant="outlined" />

      <div data-netlify-recaptcha="true"></div>
      
      <Button 
      onClick={handleSubmit(onSubmit)}
      variant="contained" 
      endIcon={<SendIcon />}>
        Send
      </Button>
    </form>
    
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert variant="filled" severity={severityLevel} onClose={handleClose} sx={{ width: '100%' }}>
        {responseMessage}
      </Alert>
    </Snackbar>
  </div>)
};

export default ContactMe;
