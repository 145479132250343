import React from 'react';
import styles from './SectionTitle.module.scss';

const SectionTitle = ({title}) => (
  <h2 className={styles.SectionTitle}>
    {title}
  </h2>
);

export default SectionTitle;
