import React from 'react';
import styles from './hero.module.scss';
import SectionTitle from '../../elements/SectionTitle/SectionTitle';

const Hero = ({assets}) => {
  const [bgImg,setBgImg] = React.useState('')
  const [profilePic,setProfilePic] = React.useState('')
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    if(assets){
      setBgImg(assets.HeroBackground)
      setProfilePic(assets.HeroMainPic)
      setLoaded(true)
    }
  },[assets])


  return <div className={styles.Hero}>
      <div 
       style={{
        backgroundImage: `url(${bgImg})`
      }}
      className={styles.Overlay}></div>
        {loaded && <>
          <img 
            src={profilePic}
            className={styles.MainImage}
            alt="Mario Kirolos"
          />
          <SectionTitle title="Mario Kirolos" />
          <h2>Software Engineer</h2>
        </>}
      
</div>
}

export default Hero;
