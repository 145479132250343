import { client } from "./contentful";

class API {
  constructor(type = "blog", order_by = null, reverse = false) {
    this.type = type;
    this.order_by = order_by;
    this.reverse = reverse;
  }

  async render() {
    if (!this.saved_locally()) {
      //Not saved, retrieve new one
      try {
        return this.fetch();
      } catch (err) {
        console.error("Can not run the fetch");
        console.error(err);
      }
    } else {
      return this.saved_locally();
    }
  }

  saved_locally() {
    const cached = JSON.parse(localStorage.getItem(`cached_${this.type}`));

    if (!cached || Math.abs(new Date() - new Date(cached.time)) > 86400000) {
      //Cache not found or more than one day
      return false;
    }

    return cached.posts;
  }

  async fetch() {
    try {
      const obj = {
        content_type: this.type,
        select: "fields",
      };

      if (this.order_by != null) {
        let r = this.reverse ? "-" : "";
        obj.order = `${r}fields.${this.order_by}`;
      }

      const posts = await client.getEntries(obj);

      const sanitized_posts = posts.items.map((item) => {
        const image = item.fields.image;
        const id = item.sys.id;

        return {
          id,
          ...item.fields,
          image,
        };
      });

      const caching = { posts: sanitized_posts, time: new Date() };
      localStorage.setItem(`cached_${this.type}`, JSON.stringify(caching));

      return sanitized_posts;
    } catch (err) {
      console.error(err);
    }
  }

  async single(slug) {
    let posts = await this.saved_locally();
    let single = posts.filter((post) => post.slug === slug);

    // if not found, fetch again
    if (single.length === 0) {
      posts = this.fetch().then((posts) => {
        single = posts.filter((post) => post.slug === slug);
      });
    }

    // if not found on fetch, return false
    if (single.length === 0) return false;

    return single[0];
  }
}

export default API;
