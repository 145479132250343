import React from "react";
import styles from "./skills.module.scss";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import API from "../../Services/API";
import SectionTitle from "../../elements/SectionTitle/SectionTitle";
import SkillsFilter from "../../elements/SkillsFilter/SkillsFilter";

const Skills = () => {
  const [skills, setSkills] = React.useState([]);
  const [skillsFilter, setSkillsFilter] = React.useState([]);
  const [activeSkill, setActiveSkill] = React.useState("all");

  React.useEffect(() => {
    const api = new API("codingSkills", "technology");

    api.render().then((data) => {
      setSkills(data);

      const filters = new Set();
      filters.add("all");
      data.map((item) => filters.add(item.category));

      setSkillsFilter(filters);
    });
  }, []);

  return (
    <div className={styles.Skills}>
      {skills ? (
        <>
          <SectionTitle title="Coding Skills" />
          <SkillsFilter
            set_of_filters={skillsFilter}
            activeSkill={activeSkill}
            setActiveSkill={setActiveSkill}
          />
          <Grid container spacing={2}>
            {skills.map((item) => (
              <Grid
                className={
                  activeSkill === "all" ||
                  activeSkill.toLowerCase() === item.category.toLowerCase()
                    ? styles.activeSkill
                    : styles.hiddenSkill
                }
                key={item.id}
                xs={12}
                sm={4}
                category={item.CategoryName}
              >
                <img
                  className={styles.languageImage}
                  src={
                    item.languageImageFile
                      ? item.languageImageFile.fields.file.url
                      : item.languageImage
                  }
                  alt={item.technology}
                />
                {item.technology}
                <span className={styles.CategoryName}>{item.category}</span>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={item.percentage}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      {item.percentage}%
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <span className="loading"></span>
      )}
    </div>
  );
};
export default Skills;
