
import API from '../Services/API';

export default class Assets{
    constructor(){
        const assets = new API('assets')
        let items = {}
        return assets.render()
        .then(data => data.map(item => items[item.assetTitle] = item.asset.fields.file.url))
        .then(() => items)
    }
}