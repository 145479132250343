import React from 'react';
import styles from './education.module.scss';
import API from '../../Services/API'

import SectionTitle from '../../elements/SectionTitle/SectionTitle';
import CustomDate from '../../elements/CustomDate/CustomDate';

import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const Education = () => {
  const [educationList, setEducationList] = React.useState([]);


  React.useEffect(() => {
    const api = new API('education' , 'dateOfGraduaction', true)

    api.render()
    .then(data => setEducationList(data))
  },[])

  return <div className={styles.Education}>
  <SectionTitle title="Education" />
  <Grid container spacing={2}>
  {educationList.map(item => (
    <Grid key={item.id} md={4} xs={12}>
      <Card className={styles.CardContainer}>
      <CardMedia
        className={styles.SchoolLogo}
        component="img"
        height="140"
        image={item.image.fields.file.url}
        alt={`${item.schoolName} logo`}
      />
      <CardContent>
        <Typography className={styles.SchoolName} gutterBottom variant="h6" component="div">
          {item.schoolName}
        </Typography>
      </CardContent>
      <CardActions className={styles.CardActions}>
        <Typography size="small">{item.location}</Typography>
        <Typography size="small"><CustomDate date={item.dateOfGraduaction} /></Typography>
      </CardActions>
      </Card>
    </Grid>
  ))}
  </Grid>

</div>
};

export default Education;
