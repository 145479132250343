import React from 'react';
import Hero from './components/hero/hero'
import About from './components/about/about'
import Experience from './components/experience/experience'
import Education from './components/education/education'
import References from './components/references/references'
import Skills from './components/skills/skills'
import ContactMe from './components/contact-me/contact-me'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom'
import Assets from './utils/assets';
import ReactGA from 'react-ga';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2397ba',
    },
    secondary: {
      main: '#ad2183',
    },
  },
});

function App() {

  const [assets, setAssets] = React.useState();

  // Google Analytics
  ReactGA.initialize('UA-93817264-1');

  React.useEffect(() => {
    new Assets().then(
      results => setAssets(results)
    )
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])


  return (
    <BrowserRouter>
    <ThemeProvider theme={darkTheme}>
      <Hero assets={assets}/>
      <About assets={assets}/>
      <Experience assets={assets}/>
      <Education assets={assets}/>
      <References assets={assets}/>
      <Skills assets={assets}/>
      <ContactMe assets={assets}/>
      <CssBaseline />
    </ThemeProvider>
    </BrowserRouter>
    
  );
}

export default App;
