import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import styles from './SkillsFilter.module.scss';

const SkillsFilter = ({set_of_filters, activeSkill, setActiveSkill}) => {
  const array = Array.from(set_of_filters)
  
  const handleClick = (item) => {
    setActiveSkill(item)
  }

  return <div className={styles.SkillsFilter}>
  <ButtonGroup variant="text" aria-label="Skills Filter" sx={{ display: 'block' }}>
    {array.map(item => <Button onClick={() => handleClick(item)} className={activeSkill.toLowerCase() === item.toLowerCase() ? styles.activeSkill : ''}>{item}</Button>)}
  </ButtonGroup>
  </div>
};

export default SkillsFilter;
