import React from 'react';
import styles from './about.module.scss';
import SectionTitle from '../../elements/SectionTitle/SectionTitle';
import Grid from '@mui/material/Unstable_Grid2';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { HashLink } from 'react-router-hash-link';
import { GitHub, LinkedIn } from '@mui/icons-material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import {  faCalendarDays, faEarthAfrica, faEnvelope, faLocationDot, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'

const About = ({ assets }) => {
  const [assetsLocal, setAssetsLocal] = React.useState({})
  const [loaded, setLoaded] = React.useState(false)
  
  React.useEffect(()=> {
    if(assets){
      setAssetsLocal(assets)
      setLoaded(true)
    }
  }, [assets])  

  return (
  <div className={styles.About}>
    <SectionTitle title="About me" />
    {loaded && <Grid 
      container 
      rowSpacing={5} 
      spacing={4}>
      <Grid item md={4} xs={12}>
        <img 
          className={styles.Img}
          alt="Mario Kirolos"
          src={assetsLocal.ProfilePic}
        />
      </Grid>
      <Grid item md={4} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography varient="h3" sx={{ fontWeight: 'bold' }}>
          Personal Info
          </Typography>

          <ul className={styles.AboutList}>
            <li><span className={styles.Icon}><FontAwesomeIcon icon={faUser} /></span>Name: Mario Kirolos</li>
            <li><span className={styles.Icon}><FontAwesomeIcon icon={faCalendarDays} /></span>DOB: June 01 1987</li>
            <li><span className={styles.Icon}><FontAwesomeIcon icon={faLocationDot} /></span>Location: South Easton, MA</li>
            <li><span className={styles.Icon}><FontAwesomeIcon icon={faPhone} /></span>Mobile: (617) 599-4789</li>
            <li><span className={styles.Icon}><FontAwesomeIcon icon={faEnvelope} /></span>e-mail: mariofayek87@gmail.com</li>
            <li><span className={styles.Icon}><FontAwesomeIcon icon={faEarthAfrica} /></span>Languages: Arabic(Native), English(Fluent)</li>
          </ul>

        
      </Grid>
      <Grid item md={4} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography varient="h3" sx={{ fontWeight: 'bold' }}>
          About Me
        </Typography>

        <Typography className={styles.AboutMeSection}>
          A multi-skilled IT manager with good all-round supervisory and technical expertise. Very capable with a proven ability to ensure the smooth running of ICT systems and to provide IT services that will improve the efficiency and performance of a company. Extensive practical knowledge of complex systems builds, hardware and software testing, network support, technical support and computer repairs. Looking for a new and challenging managerial position, one that will make best use of my existing skills & experiences also further my personal development.
        </Typography>


      </Grid>
      
      {/* Share Buttons */}
      <Grid item md={3} xs={6}>
        <HashLink 
        className={`${styles.Button} ${styles.HireMe}`}
        smooth
        to="#contactMe">
          <QuestionAnswerIcon />
          Hire me
          </HashLink>
      </Grid>
      <Grid item md={3} xs={6}>
          <a 
          href="https://www.linkedin.com/in/mariokirolos/"
          target="_blank" 
          className={`${styles.Button} ${styles.LinkedIn}`} rel="noreferrer">
          <LinkedIn />
          LinkedIn
        </a>
      </Grid>
      <Grid item md={3} xs={6}>
        <a 
          href="https://github.com/mariokirolos"
          target="_blank" 
          className={`${styles.Button} ${styles.GitHub}`} rel="noreferrer">
          <GitHub />
          GitHub
        </a>
      </Grid>
      <Grid item md={3} xs={6}>
        <a 
          href={assetsLocal.Resume}
          target="_blank" 
          className={`${styles.Button} ${styles.Resume}`} rel="noreferrer">
          <SimCardDownloadIcon />
          Download Resume
        </a>
      </Grid>
    </Grid>}
    
  </div>
)};

export default About;
